.mplus-new-stand-out {
  background-color: var(--mp-link-active);
  color:var(--mp-primary-white);
  height: 36px;
}

.mplus-new-stand-out:hover {
  background-color: rgba(27,75,197, 0.8);
  color:var(--mp-primary-white) !important;
  height: 36px;
  box-shadow: 0px 8px 24px rgba(160, 163, 176, 0.24), 0px 4px 8px rgba(64, 72, 97, 0.24);
}

.mplus-new-stand-out:focus {
  background-color: rgba(27,75,197, 0.8);
  color: var(--mp-primary-white) !important;
  outline: none !important;
  height: 36px;
  box-shadow: 0px 0px 0px 2px var(--mp-primary-white), 0px 0px 0px 4px rgba(27,75,197, 0.3);
}

.mplus-new-stand-out:active {
  background-color: rgba(27,75,197, 0.8) !important;
  color: var(--mp-primary-white) !important;
  outline: none !important;
  border: 2px solid var(--mp-link-active)s;
  height: 36px;
}

.mplus-new-stand-out:disabled {
  background: var(--mp-disabled-btn-grey);
  color: var(--mp-primary-white);
  mix-blend-mode: normal;
  border-radius: 4px;
  height: 36px;
}

.button-light {
  background-color: var(--mp-primary-white);
  color: var(--mp-link-active);
  border-color: var(--mp-link-active);
  height: 36px;
}

.button-light:hover {
  background-color:  rgba(27,75,197, 0.05);
  color: var(--mp-link-active);
  border-color: var(--mp-link-active);
  height: 36px;
}

.button-light:active {
  background-color:  rgba(27,75,197, 0.1);
  color: var(--mp-link-active);
  outline: none !important;
  border-color: var(--mp-link-active);
  height: 36px;
}

.button-light:focus {
  background-color:  rgba(27,75,197, 0.05);
  color: var(--mp-link-active);
  border-color: var(--mp-link-active);
  outline: none !important;
  box-shadow: 0px 0px 0px 2px var(--mp-primary-white), 0px 0px 0px 4px rgba(27,75,197, 0.3);
  height: 36px;
}

.button-light-disabled,.button-light-disabled:hover, dx-button .button-light-disabled {
  border:1px solid var(--mp-disabled-btn-grey);
  border-radius: 4px;
  color: var(--mp-disabled-button-text-color);
  height: 36px;
  font-weight: 700;
  font-size: 12px;
  outline: none !important;
}

.dx-toolbar-button .dx-button-has-text {
  background-color: var(--mp-primary-white);
  color: var(--mp-link-active);
  border-color: var(--mp-link-active);
}

.dx-toolbar-button .dx-button-has-text:hover {
  background-color:  rgba(27,75,197, 0.05);
}

.dx-toolbar-button .dx-button-has-text:active {
  background-color:  rgba(27,75,197, 0.1);
}

.dx-toolbar-button .dx-button-has-text:focus {
  background-color:  rgba(27,75,197, 0.05);
  box-shadow: 0px 0px 0px 2px var(--mp-primary-white), 0px 0px 0px 4px rgba(27,75,197, 0.3);
}

.dx-scheduler-appointment-popup{
  .dx-popup-done.dx-button-has-text{
    background-color: var(--mp-link-active) !important;
    color: var(--mp-primary-white) !important;
  }

  .dx-popup-done.dx-button-has-text:hover{
    background-color: rgba(27,75,197, 0.8) !important;
    color: var(--mp-primary-white) !important;
    box-shadow: 0px 8px 24px rgba(160, 163, 176, 0.24), 0px 4px 8px rgba(64, 72, 97, 0.24);
  }

  .dx-toolbar-after{
    display: flex;
  }

  .dx-toolbar-item{
    &:first-child{
        padding: 0;
        order: 1;
    }

    &:last-of-type{
      order: 0;
      padding: 0 10px 0 0 !important;
    }
  }
}

.dx-fileuploader-button {
  height: 36px;
  color: var(--mp-link-active);
  border-color: var(--mp-link-active);
}

.dx-fileuploader-button:hover {
  background-color: rgba(27,75,197, 0.05);
  border-color: var(--mp-link-active);
}

.dx-fileuploader-button:active {
  background-color: rgba(27,75,197, 0.1);
  border-color: var(--mp-link-active);
  box-shadow: 0px 0px 0px 2px var(--mp-primary-white), 0px 0px 0px 4px rgba(27,75,197, 0.3);
}

.dx-fileuploader-button:focus {
  background-color: rgba(27,75,197, 0.05);
  border-color: var(--mp-link-active);
  box-shadow: 0px 0px 0px 2px var(--mp-primary-white), 0px 0px 0px 4px rgba(27,75,197, 0.3);
}
