/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 28, 2020 */

@font-face {
    font-family: 'Poppins';
    src: url('poppins-bold-webfont.woff2') format('woff2'),
         url('poppins-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('poppins-italic-webfont.woff2') format('woff2'),
         url('poppins-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('poppins-medium-webfont.woff2') format('woff2'),
         url('poppins-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('poppins-regular-webfont.woff2') format('woff2'),
         url('poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
