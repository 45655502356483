.above-footer-bar {
    bottom: 65px;
}

.with-slider-open {
    right: 460px;
}

.toast-modal-mid {
    margin-left: 50%;
    margin-right: 50%;
    top: 50%;
}
