:root {
    /* Categorical */
    --mp-kpi-Salmon-Base: #EC6F56;
    --mp-kpi-Blue-base: #418ACA;
    --mp-kpi-Yellow-green-Base: #81A612;
    --mp-kpi-Teal-dark: #05737D;
    --mp-kpi-Gold-Base: #B89A00;
    --mp-kpi-Purple-base: #B668EE;
    --mp-kpi-Seafoa-Green-Base: #2FBC82;
    --mp-kpi-Magenta-Dark: #A42C73;
    --mp-kpi-Brown-Base: #A4887F;
    --mp-kpi-Navy-Base: #8585E1;
    --mp-kpi-Forest-green-Base: #60AE3A;
    --mp-kpi-Orchid-Dark: #942E94;
    --mp-kpi-Salmon-Dark: #A03E2B;
    --mp-kpi-Blue-dark: #205A8D;
    --mp-kpi-Yellow-green-Dark: #506809;
    --mp-kpi-Teal-darker: #013E44;
    --mp-kpi-Gold-Dark: #715F00;
    --mp-kpi-Purple-dark: #7D34B2;
    --mp-kpi-Seafoam-green-Dark: #177850;
    --mp-kpi-Magenta-Darker: #5D0037;
    --mp-kpi-Brown-Dark: #6D5148;
    --mp-kpi-Navy-Dark: #4E4EAA;
    --mp-kpi-Forest-green-Dark: #386F1D;
    --mp-kpi-Orchid-Darker: #530053;

    /* Sequential Colors(Navy to CornFlower */
    --mp-kpi-Navy-Lighter: #E0E0F8;
    --mp-kpi-Navy-Light: #C2C2F0;
    --mp-kpi-Navy-Base: #8585E1;
    --mp-kpi-Cornflower-blue-Dark: #345BAF;
    --mp-kpi-Cornflower-blue-Darker: #0B3080;

    /* Divergent Colors (Red to Blue) */
    --mp-kpi-maroon: #7D0101;
    --mp-kpi-Dark-Candy-Apple-Red: #A10303;
    --mp-kpi-Red-base: #D20707;
    --mp-kpi-Light-Coral: #E98383;
    --mp-kpi-Baby-Pink: #F4C1C1;
    --mp-kpi-Snow: #F9FAFB;
    --mp-kpi-Azureish-White: #CFE2F2;
    --mp-kpi-Pale-Cerulean: #A0C5E5;
    --mp-kpi-Blue-base: #418ACA;
    --mp-kpi-Blue-dark: #205A8D;
    --mp-kpi-Prussian-Blue: #062B4D;

    /* single hue, rest all are listed above */
    --mp-kpi-Blue-darker: #0D3D68;
}