@font-face {
    font-family: 'Eptura Flexible';
    src: url("../assets/fonts/EpturaFlexible-Bold.woff2") format('woff');
    unicode-range: U+00-FF, U+980-9FF;
}

@font-face {
    font-family: 'Eptura Flexible';
    src: url("../assets/fonts/EpturaFlexible-Medium.woff2") format('woff');
    unicode-range: U+00-FF, U+980-9FF;
}

@font-face {
    font-family: 'Eptura Flexible';
    src: url('../assets/fonts/EpturaFlexible-Regular.woff2') format('woff');
    unicode-range: U+00-FF, U+980-9FF;
}
body:not(.bootstrap-override) {
    /*
        bootstrap-override is not a real class that will ever be applied.
        It's a specificity hack to override Bootstrap's default font-family rule.
    */
    font-family: 'Eptura Flexible', sans-serif;
}
/* 
  This is a workaround for the new way we're loading the asset floor plan MFE.
  If anyone knows how to get it into the floor-plan-viewer.component.ts styles
  it should be there. It seems to be something to do with trying to access something
  in a web component which uses the shadow dom.
 */
.fpv-wrapper > div {
  height: calc(100vh - 54px);
}
