.slider-header {
  background-color: var(--mp-new-footer-color);
  color: var(--mp-icon-color);
  height: 50px;
  border-radius: 8px 0 0 0;
  display: flex;
  align-items: center;
  padding: 5px;
}

.slider-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: var(--mp-icon-color);
  padding: 5px;
}

.slider-title i {
  font-style: normal;
  font-weight: 300;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  padding: 5px;
  color: var(--mp-icon-color);
}

.slider-body {
  margin-top: 0px;
  padding: 14px;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 190px);
  overflow-x: hidden;
  background-color: var(--mp-primary-white);
}

.slider-footer {
  display: flex;
  justify-content: flex-end;
  text-align:center;
  padding:10px;
  margin-right: 10px;
}