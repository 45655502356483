.mplusMainPanelHeader {
    background-color: #0065A1 !important;
}

.mplusSubPanelHeader {
    background-color: #6ba2b9 !important;
}

.mplusSubPanelBorder {
    border-color: #607775 !important;
}

.panel .panel-primary .mplusMainPanelBorder {
    border-color: #003A5D !important;
}

.mplusWarning {
    background-color: #FFAB4C;
    border-color: #FFAB4C;
}

.mplusGrayButton {
    background-color: #99A4AE;
    border-color: #99A4AE;
}

.dx-header-row {
    background: linear-gradient(45deg, rgb(71, 71, 71) 3%, rgb(117, 117, 117) 103%) !important;
}

.mplusNote {
    background-color: #6ba2b9 !important;
    color: #00416a !important;
}

.panel .panel-info .mplusNoteBorder {
    border-color: #bbdce5 !important;
}

.tabsSmall {
    margin-top: 10px;
    margin-left: 10px;
    max-width: 100%;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    padding-left: 15px;
    padding-right: 15px;
}

.tabsMediumAndUp {
    /*display: inline-block;*/
    max-width: 70%;
    min-height: 630px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    float: left;
}

[app-disabled] {
    opacity: 0.95;
    pointer-events: none;
}

.mp-primary-button,
.mp-secondary-button {
    /* copied from bootstrap btn class*/
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 1.42857143;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* mplus common properties */
    border-radius: 6px;
    padding: 8px 12px;
}

.mp-primary-button {
    background-color: var(--mp-bright-blue);
    color: white;
}

    .mp-primary-button:hover:enabled {
        background-color: var(--mp-hover-blue);
        color: white;
    }

.mp-secondary-button {
    background-color: white;
    color: var(--mp-bright-blue);
    border: 1px solid var(--mp-bright-blue);
}

    .mp-secondary-button:hover:enabled {
        background-color: #eee;
        color: var(--mp-bright-blue);
    }